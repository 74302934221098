const frFr = {
    global: {
        orderNumber: "Numéro de commande",
        trackAndTrace: "Suivi des envois",
        name: "Nom",
        customerNumber: "Numéro client",
        emailAddress: "Adresse e-mail",
        phoneNumber: "Numéro de téléphone",
        shipmentDate: "Date d'expédition",
        receivedDate: "Date de réception",
        pickedUpDate: "Date de ramassage",
        pendingOrders: "Les ordres en attente",
        receivedOrders: "Commandes reçues",
        expiredOrders: "Commandes expirées",
        pickedUpOrders: "Ramassé les commandes",
        results: "Résultats",
        ordersFound: (amount) => `${amount} commande(s) trouvée(s)`,
        processOrder: "Traiter la commande",
        viewProducts: "Voir les produits",
        close: "Fermer",
        order: "Ordre",
        orderComplete: "Cette commande est complète",
        orderPartiallyComplete: (amount, total) => `${amount} de ${total} produits dans cette commande`,
        articleNumber: "Numéro d'article",
        barcode: "Code à barre",
        color: "Couleur",
        size: "Taille",
    },
    navigation: {
        open: "Ouvert",
        received: "Reçu",
        expired: "Expiré",
        pickedup: "Ramassé"
    },
    messages: {
        loading: {
            default: "Charger...",
        },
        error: {
            noAccess: "Pas d'accès",
            noBranch: "Remarque: Vous n'êtes pas lié à une succursale, vous ne pouvez donc <b>pas</b> afficher les commandes.",
            orderShipmentBySearchValueNotFound: (searchValue) => `Malheureusement, aucune commande n'a été trouvée avec la valeur: ${searchValue}`,
        },
        success: {
            orderShipmentSubmitted: "De bestellingstatus is opgeslagen.",
        },
        confirmation: {
            received: (orderNumber) => `Êtes-vous sûr de vouloir communiquer la commande ${orderNumber} comme reçue au client?`,
            pickedUp: (orderNumber) => `Êtes-vous sûr de vouloir communiquer la commande ${orderNumber} comme recueillie au client??`,
            ok: "OK",
            cancel: "Annuler"
        },
    }
};

export default frFr;
