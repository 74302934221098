const nlNl = {
    global: {
        orderNumber: "Ordernummer",
        trackAndTrace: "Track & Trace",
        name: "Naam",
        customerNumber: "Klantnummer",
        emailAddress: "E-mailadres",
        phoneNumber: "Telefoonnummer",
        shipmentDate: "Verzenddatum",
        receivedDate: "Ontvangstdatum",
        pickedUpDate: "Ophaaldatum",
        pendingOrders: "Openstaande bestellingen",
        receivedOrders: "Ontvangen bestellingen",
        expiredOrders: "Verlopen bestellingen",
        pickedUpOrders: "Opgehaalde bestellingen",
        results: "Resultaten",
        ordersFound: (amount) => `${amount} bestelling(en) gevonden`,
        processOrder: "Bestelling verwerken",
        viewProducts: "Producten bekijken",
        close: "Sluiten",
        order: "Bestelling",
        orderComplete: "Deze bestelling is compleet",
        orderPartiallyComplete: (amount, total) => `${amount} van ${total} producten in deze bestelling`,
        articleNumber: "Artikelnummer",
        barcode: "Barcode",
        color: "Kleur",
        size: "Maat",
    },
    navigation: {
        open: "Openstaand",
        received: "Ontvangen",
        expired: "Verlopen",
        pickedup: "Opgehaald"
    },
    messages: {
        loading: {
            default: "Laden...",
        },
        error: {
            noAccess: "Geen toegang",
            noBranch: "Let op: U bent niet gekoppeld aan een vestiging, hierdoor kunt u <b>geen</b> orders inzien.",
            orderShipmentBySearchValueNotFound: (searchValue) => `Er is helaas geen bestelling gevonden met de waarde: ${searchValue}`,
        },
        success: {
            orderShipmentSubmitted: "De bestellingstatus is opgeslagen.",
        },
        confirmation: {
            received: (orderNumber) => `Weet je zeker dat je order ${orderNumber} als ontvangen wilt communiceren naar de klant?`,
            pickedUp: (orderNumber) => `Weet je zeker dat je order ${orderNumber} als opgehaald wilt communiceren naar de klant?`,
            ok: "OK",
            cancel: "Annuleren",
        },
    }
};

export default nlNl;
