const enGb = {
    global: {
        orderNumber: "Order number",
        trackAndTrace: "Track & Trace",
        name: "Name",
        customerNumber: "Customer number",
        emailAddress: "E-mail address",
        phoneNumber: "Phone number",
        shipmentDate: "Shipment date",
        receivedDate: "Received date",
        pickedUpDate: "Picked up date",
        pendingOrders: "Pending orders",
        receivedOrders: "Received orders",
        expiredOrders: "Expired orders",
        pickedUpOrders: "Picked up orders",
        results: "Results",
        ordersFound: (amount) => `${amount} order(s) found`,
        processOrder: "Process order",
        viewProducts: "View products",
        close: "Close",
        order: "Order",
        orderComplete: "This order is complete",
        orderPartiallyComplete: (amount, total) => `${amount} of ${total} products in this order`,
        articleNumber: "Article number",
        barcode: "Barcode",
        color: "Color",
        size: "Size",
    },
    navigation: {
        open: "Open",
        received: "Received",
        expired: "Expired",
        pickedup: "Finished"
    },
    messages: {
        loading: {
            default: "Loading...",
        },
        error: {
            noAcces: "No access",
            noBranch: "Note: You are not linked to a branch, so you can <b>not</b> view orders.",
            orderShipmentBySearchValueNotFound: (searchValue) => `Unfortunately no order was found with the value: ${searchValue}`,
        },
        success: {
            orderShipmentSubmitted: "De bestellingstatus is opgeslagen.",
        },
        confirmation: {
            received: (orderNumber) => `Are you sure you want to communicate order ${orderNumber} to the customer as received?`,
            pickedUp: (orderNumber) => `Are you sure you want to communicate order ${orderNumber} to the customer as picked up?`,
            ok: "OK",
            cancel: "Cancel"
        },
    }
};

export default enGb;
