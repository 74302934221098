import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { sharedRoutes } from "./sharedRoutes";

const PendingOrdersPage = lazy(() => import("pages/PendingOrdersPage"));
const ReceivedOrdersPage = lazy(() => import("pages/ReceivedOrdersPage"));
const ExpiredOrdersPage = lazy(() => import("pages/ExpiredOrdersPage"));
const PickedUpOrdersPage = lazy(() => import("pages/PickedUpOrdersPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <Outlet />,
        children: [
            {
                key: "pendingOrders",
                path: "/",
                title: "navigation.open",
                index: true,
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <PendingOrdersPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "receivedOrders",
                path: "/received",
                title: "navigation.received",
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ReceivedOrdersPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "expiredOrders",
                path: "/expired",
                title: "navigation.expired",
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                    <ExpiredOrdersPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "pickedUpOrders",
                path: "/pickedup",
                title: "navigation.pickedup",
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <PickedUpOrdersPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "profile",
                path: "/profile",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                children: []
            },
            ...sharedRoutes
        ]
    },
];
