const deDe = {
    global: {
        orderNumber: "Bestellnummer",
        trackAndTrace: "Track & Trace",
        name: "Name",
        customerNumber: "Kundennummer",
        emailAddress: "E-Mail-Addresse",
        phoneNumber: "Telefonnummer",
        shipmentDate: "Versanddatum",
        receivedDate: "Empfangsdatum",
        pickedUpDate: "Abholdatum",
        pendingOrders: "Ausstehende Bestellungen",
        receivedOrders: "Bestellungen eingegangen",
        expiredOrders: "Abgelaufene Bestellungen",
        pickedUpOrders: "Gesammelte Bestellungen",
        results: "Ergebnisse",
        ordersFound: (amount) => `${amount} Bestellung(en) gefunden`,
        processOrder: "Prozessauftrag",
        viewProducts: "Produkte ansehen",
        close: "Schließen",
        order: "Bestellung",
        orderComplete: "Diese Bestellung ist abgeschlossen",
        orderPartiallyComplete: (amount, total) => `${amount} von ${total} Produkten in dieser Bestellung`,
        articleNumber: "Artikel Nummer",
        barcode: "Barcode",
        color: "Farbe",
        size: "Größe",
    },
    navigation: {
        open: "Open",
        received: "Erhalten",
        expired: "Abgelaufen",
        pickedup: "Abgeholt"
    },
    messages: {
        loading: {
            default: "Wird geladen...",
        },
        error: {
            noAccess: "Kein Zugang",
            noBranch: "Hinweis: Sie sind nicht mit einer Filiale verknüpft und können daher <b>keine</b> Bestellungen einsehen.",
            orderShipmentBySearchValueNotFound: (searchValue) => `Leider wurde keine Bestellung mit dem Wert gefunden: ${searchValue}`,
        },
        success: {
            orderShipmentSubmitted: "De bestellingstatus is opgeslagen.",
        },
        confirmation: {
            received: (orderNumber) => `Sind Sie sicher, dass Sie dem Kunden die Bestellung ${orderNumber} als erhalten mitteilen möchten?`,
            pickedUp: (orderNumber) => `Sind Sie sicher, dass Sie dem Kunden die Bestellung ${orderNumber} als abgeholt mitteilen möchten?`,
            ok: "OK",
            cancel: "Stornieren"
        },
    }
};

export default deDe;
